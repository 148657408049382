<template>
	<div class="text-sm bg-yellow p-1 px-2 border border-black rounded-lg font-bold" :class="props.classes">
		<span>SPONSOR</span>
	</div>
</template>

<script setup>
const props = defineProps({
	classes: {
		type: String,
		default: ''
	}
});
</script>