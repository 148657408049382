<template>
	<NuxtLink :to="server.href" class="banner_container  select-none">
		<picture v-if="server.banner && server.banner.endsWith('.webp')">
			<source :srcset="server.banner" type="image/webp">
			<source srcset="/no_banner.webp" :alt="server.title">
			<img src="/no_banner.webp" :alt="server.title" loading="lazy">
		</picture>
		<video
				v-else
				ref="videoEl" :poster="poster" loop playsinline width="467" height="59" :title="server.title"
				muted disablePictureInPicture preload="none">
			<source muted :src="banner" type="video/mp4" :alt="server.title">
			<source muted src="/no_banner.mp4#t=0.1" type="video/mp4" :alt="server.title">
		</video>
	</NuxtLink>
</template>

<script setup>
const observer = ref(null);
const videoEl = shallowRef(null);

const observed = ref(false);
const loaded = ref(false);

const { server } = defineProps({
	server: {
		type: Object,
		required: true
	},
});

let banner = server.banner + '#t=0.1';
const bannerWebm = server.banner ? server.banner.replace(".mp4", ".webm") : null;

if (server.banner === null) {
	banner = '/no_banner.webp'; // Keep html happy for a while
}

const poster = computed(() => {
	if (!server.banner || !server.banner.endsWith('.mp4')) return "/no_banner.webp";
	return server.banner.replace(/\/(banners?|legacy_banners)/, '/thumbnails') + '.webp';
});

onMounted(() => {
	// Touch listener for low-power mode.
	const body = document.querySelector('body');
	body.addEventListener('touchstart', () => {
		if (loaded.value) return; // Banner already loaded
		if (!observed.value) return; // Banner not observed yet (Not in view) thus should not be loaded.
		loadBanner(videoEl.value); // Retry loading banner
	});

	if (!videoEl.value) return;

	// if observer is not supported, load the banner immediately
	if (!('IntersectionObserver' in window)) {
		loadBanner(videoEl.value);
		return;
	}

	observer.value = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) return;
			loadBanner(entry.target);
			observed.value = true;
			observer.value.unobserve(videoEl.value);
		});
	}, {
		rootMargin: '200px',
	});

	observer.value.observe(videoEl.value);
});

onUnmounted(() => {
	if (observer.value) {
		observer.value.disconnect();
		observer.value = null;
	}

	if (videoEl.value) {
		videoEl.value.src = '';
		videoEl.value.srcObject = null;
		videoEl.value.remove()
		videoEl.value.removeEventListener('touchstart', () => {});
		videoEl.value.removeEventListener('error', () => {});
		videoEl.value.removeEventListener('loadeddata', () => {});
		videoEl.value = null;
	}
});

async function attemptPlay(videoElement) {
	loaded.value = true;
	videoElement.play().catch(() => {
		loaded.value = false;
		try {
			videoElement.current.play().catch((_) => {});
		} catch (e) {
			// We dont care. dont log it
		}
	})
}

async function loadBanner(videoElement) {
	if (!videoElement) return;
	const onLoadedData = () => {
		loaded.value = true;
		attemptPlay(videoElement);
		videoElement.removeEventListener('loadeddata', onLoadedData);
	};
	videoElement.addEventListener('error', () => {
		// console.log('Error loading banner');
	});
	videoElement.addEventListener('loadeddata', onLoadedData);
	videoElement.load();
}

</script>

<style scoped lang="scss">
.banner_container {
  position: relative;
  width: 100%;
  border-radius: 7.77px;
  max-width: 467px;
  border: 2px solid black;
  display: block;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
  }

  video,
  picture,
  img {
    object-fit: fill;
    position: relative;

    border-radius: inherit;

    height: 60px;
    width: 100%;
  }

  video::-webkit-media-controls {
    display: none;
  }

  .banner_fail {
    position: absolute;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: -1;
  }
}

@media (max-width: 968px) {
  .banner_container {
    width: 95%;
    margin: 0 auto;
  }

  video,
  img {
    display: block;
    margin: 0 auto;
  }
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

</style>