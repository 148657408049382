export function useSortFilter() {
	return useState('sortFilter', () => 'ranked');
}

export function usePlatformFilter() {
	return useState('serverPlatformFilter', () => 'all');
}

export function useTimeFilter() {
	return useState('timeFilter', () => 'this-month');
}