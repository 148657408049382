<template>
	<div class="mt-32 flex gap-2 justify-center">
		<!--		Back button     -->
		<IndexPagingButton
				:link="currentPath === '/rank' && currentPage - 1 <= 1 ? '/' : `${currentPath}` + (currentPage - 1 <= 1 ? '' : `/${currentPage - 1}`)"
				:disabled="currentPage <= 1"
				classes="flex gap-2">
			<span>&lt</span>
			<span>Back</span>
		</IndexPagingButton>

		<!--		Previous pages     -->
		<IndexPagingButton v-for="page in previousPages" :key="'page-' + page"
		                   :link="currentPath === '/rank' && page < 2 ? '/' : `${currentPath}` + (page !== 1 && page !== 0 ? `/${page}` : '')"
		                   :content="page"/>


		<!--		Current page     -->
		<IndexPagingButton :content="currentPage"/>

		<!--		Next pages     -->
		<IndexPagingButton v-for="page in nextPages" :key="'page-' + page"
		                   :link="`${currentPath}/${page}`" :content="page"/>

		<!--		Next button     -->
		<IndexPagingButton :link="`${currentPath}/${props.currentPage + 1}`" :disabled="currentPage >= props.maxPage"
		                   classes="flex gap-2">
			<span>Next</span>
			<span>&gt</span>
		</IndexPagingButton>
	</div>
</template>

<script setup>

const props = defineProps({
	maxPage: {
		type: Number,
		required: true,
	},
	currentPage: {
		type: Number,
		required: true,
	},
	filter: {
		type: String,
	},
});
const currentPath = ref(getCurrentPath());

function getCurrentPath() {
	const { fullPath, params } = useRoute();
	let currentPath = fullPath;

	if (currentPath.includes("/login") || currentPath === "/") {
		currentPath = "/rank";
	}

	if (params.page) {
		currentPath = currentPath.replace(`/${params.page}`, "");
	} else if (currentPath.endsWith("/1")) {
		currentPath = currentPath.replace("/1", "");
	}

	if (currentPath.endsWith("/")) {
		currentPath = currentPath.slice(0, -1);
	}

	return currentPath;
}

const previousPages = [];
const nextPages = [];

for (let i = props.currentPage - 1; i >= Math.max(props.currentPage - 4, 1); i--) {
	previousPages.unshift(i);
}

for (let i = props.currentPage + 1; i <= Math.min(props.currentPage + 4, props.maxPage); i++) {
	nextPages.push(i);
}

// Adjust the number of pages if there are too many in one direction
while (previousPages.length + nextPages.length > 4) {
	if (previousPages.length > nextPages.length) {
		previousPages.shift(); // Remove from the beginning of previousPages
	} else {
		nextPages.pop(); // Remove from the end of nextPages
	}
}</script>