<template>
	<NuxtLink v-if="props.link && !disabled" :to="props.link"
	          class="px-4 py-2 rounded-xl text-sm bg-gray-200
					 hover:bg-gray-500
					 m-xs:px-2 m-xs:text-xs"
			  :class="props.classes">
		<template v-if="props.content">
			{{ props.content }}
		</template>
		<template v-else>
			<slot/>
		</template>
	</NuxtLink>
	<div v-else
	     class="px-4 py-2 rounded-xl text-sm
	            m-xs:px-2 m-xs:text-xs"
	     :class="{ [props.classes]: true }">
		<template v-if="props.content">
			{{ props.content }}
		</template>
		<template v-else>
			<slot/>
		</template>
	</div>
</template>

<script setup>
const props = defineProps({
	content: {
		type: [String, Number],
		default: null
	},
	link: {
		type: String,
		default: null
	},
	classes: {
		type: String,
		default: ''
	},
	disabled: {
		type: Boolean,
		default: false
	}
});
</script>